.eventList{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 251, 44);
}

.upcoming-event{
    outline-style: inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.upcoming-event img{
    object-fit: fill;
    height: 80%;
    width: 70%;
    margin-top: 10px;
}