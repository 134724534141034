.menu {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 251, 44);
    object-fit: contain;
    overflow-y: scroll;
    overflow-x: scroll;
  }