.footer {
    width: 100%;
    height: 200px;
    background-color: #18c0f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .socialMedia svg {
    color: black;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: black;
  }